<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="elementData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t(ELEMENT_NAME + '.search.error.fetching_data')}}
      </h4>
    </b-alert>
    <template v-if="elementData">
      <b-row>
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
        >
        <element-info-card v-if="elementData"
            :element-data="elementData" 
            :element-name="ELEMENT_NAME" />
        </b-col>
      </b-row>
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import {
  BRow, BCol, BButton, BAlert, BLink,
} from 'bootstrap-vue'
import ElementStoreModule from '../storeModule'
import ElementInfoCard from './InfoCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BAlert,
    BLink,
    // Local Components
    ElementInfoCard,
  },
  data() {
    return {
      elementData: undefined,
      ELEMENT_APP_STORE_MODULE_NAME: '',
      ELEMENT_NAME: ''
    }
  },
  mounted() {
    this.ELEMENT_NAME = 'documental_types'
    this.ELEMENT_APP_STORE_MODULE_NAME = 'apps-dt-v-' + this.$t(this.RESOURCES_ELEMENT_NAME + ".module_name")
    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.registerModule(this.ELEMENT_APP_STORE_MODULE_NAME, ElementStoreModule)

    this.fetchElement()
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  methods: {
    fetchElement() {
      store.dispatch(this.ELEMENT_APP_STORE_MODULE_NAME + '/fetch_element', { id: router.currentRoute.params.id })
      .then(response => { 
        console.info(response)
        this.elementData = response.data 
        console.info(this.elementData)
      })
      .catch(error => {
        if (error.response.status === 404) {
          console.info('404', this.elementData)
          this.elementData = undefined
        }
      })
    },
  }
}
</script>

<style>

</style>
