<template>
  <b-card>
    <b-card-header>
      <div class="text-primary h2">
        <feather-icon
          icon="StarIcon"
          size="25"
          class="mr-0 mr-sm-50"
          variant="primary"
        />
        <span class="font-weight-bold">{{ $t(elementName + '.name') }}</span>
      </div>
    </b-card-header>
    <b-card-body>

      <b-row>
        <!-- Right Col: Table -->
        <b-col
          cols="12"
          xl="12"
        >
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(elementName+'.object.key') }}</span>
              </th>
              <td class="pb-50">
                {{ elementData.key }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(elementName+'.object.name') }}</span>
              </th>
              <td class="pb-50">
                {{ elementData.name }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(elementName+'.object.folder_type') }}</span>
              </th>
              <td class="pb-50">
                {{ elementData.folder_type.value }}
              </td>
            </tr>

          </table>
        </b-col>
      </b-row>
      <b-row class="d-flex justify-content-end">
        <b-button
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="router().go(-1)"
        >
          {{ $t('actions.back') }}
        </b-button>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BButton, BAvatar, BRow, BCol, BBadge,
} from 'bootstrap-vue'

import router from '@/router'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BBadge, BCardHeader, BCardBody,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    elementName: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
    }
  },
  methods: {
    router() {
      return router
    },
  },
}
</script>

<style>

</style>
